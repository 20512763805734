import API from './API'
import Csrf from './Csrf'

export default class Donatur {

    async getList() {
        await Csrf.getCookie()
        return API.get('/donatur')
    }
    async getDetail(id) {
        await Csrf.getCookie()
        return API.get(`/donatur/${id}`)
    }
    async store(form) {
        await Csrf.getCookie()
        return API.post('/donatur', form)
    }
    async update(id, form) {
        await Csrf.getCookie()
        return API.patch(`/donatur/${id}`, form)
    }
    async delete(id) {
        await Csrf.getCookie()
        return API.delete(`/donatur/${id}`)
    }

    async updateStatus(id, status) {
        await Csrf.getCookie()
        
        if(!status) {
            return API.patch(`/donatur/${id}/unblock`)
        }
        return API.patch(`/donatur/${id}/block`)
    }
}