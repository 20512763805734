<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
    <div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card">
                <div class="flex flex-wrap justify-content-between flex-row grid">
                    <div class="flex justify-content-start col">
                        <h5 class="mt-auto mb-auto">Detail Donatur</h5>
                    </div>
                    <div class="flex justify-content-end col">
                        <Button label="Edit" icon="pi pi-pencil" class="p-button-text" @click="openDonaturEntry"></Button>
                    </div>
                </div>
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nama</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.nama }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.email }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Alamat</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.alamat }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Jenis Kelamin</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1" v-if="detailDonatur.jenis_kelamin == 'L'">Laki-laki</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1" v-if="detailDonatur.jenis_kelamin == 'P'">Perempuan</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Tanggal Lahir</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.tgl_lahir }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nomor Telepon</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.telepon }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Pekerjaan</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.pekerjaan }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap" v-if="(this.$store.state.user.role == 'Super Admin')">
                        <div class="text-500 w-6 md:w-2 font-medium">Amil Koordinator</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ detailDonatur.nama_amil }}</div>
                    </li>
                </ul>
                <div class="flex justify-content-end col" v-if="isSuperAdmin">
                    <Button label="Hapus" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="hapusDonatur"></Button>
                </div>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="donaturEntry" :style="{width: '600px'}" header="Edit Donatur" :modal="true" class="p-fluid" :closable="false" :maximizable="true">
		<small>* wajib diisi</small>
        <form class="p-fluid">
            <fieldset :disabled="loadingRegister">
                <div class="form-group">
                    <div class="field">
                        <label for="nama">Nama Donatur *</label>
                        <InputText id="nama" v-model="detailDonatur.nama" :class="{'p-invalid':submitted && !nama}" placeholder="Nama Lengkap" />
                        <small class="p-error" v-if="submitted && !nama">Nama harus diisi.</small>
                    </div>
					<div class="field">
                        <label for="jenis_kelamin" class="mb-3">Jenis Kelamin *</label>
                        <Dropdown id="jenis_kelamin" v-model="detailDonatur.jenis_kelamin" :options="jenis_kelamins" optionLabel="label" optionValue="value" placeholder="Pilih jenis kelamin" required/>
                    </div>
					<div class="field">
						<label for="tgl_lahir" class="mb-3">Tanggal Lahir</label>
						<Calendar id="tgl_lahir" v-model="detailDonatur.tgl_lahir"  dateFormat="yy-mm-dd" placeholder="yyyy-mm-dd (contoh: 1900-01-01)" :manualInput="false" />
					</div>
					<div class="field">
                        <label for="no_hp">No Telp.</label>
                        <InputText id="no_hp" v-model="detailDonatur.telepon" placeholder="08xx-xxxx-xxxx" />
                    </div>
                    <div class="field">
                        <label for="email">Email</label>
                        <InputText id="email" v-model="detailDonatur.email" placeholder="contoh@email.com" />
                    </div>
					<div class="field">
                        <label for="pekerjaan">Pekerjaan/Perusahaan</label>
                        <InputText id="pekerjaan" v-model="detailDonatur.pekerjaan" placeholder="Jabatan/Nama Perusahaan" />
                    </div>
					<div class="field">
                        <label for="alamat">Alamat Lengkap *</label>
                        <Textarea id="alamat" v-model="detailDonatur.alamat" :class="{'p-invalid':submitted && !alamat}" placeholder="Alamat Lengkap (Jalan, Kecamatan, Kelurahan, Kota/Kabupaten, dll...)" />
                        <small class="p-error" v-if="submitted && !alamat">Alamat harus diisi.</small>
                    </div>
                </div>
            </fieldset>
        </form>
        <template #footer>
            <Button label="Batalkan" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="editDonatur" :disabled="!detailDonatur.nama || !detailDonatur.alamat" />
        </template>
    </Dialog>
	<ConfirmDialog></ConfirmDialog>
	<Toast></Toast>
</template>

<script>
    import Donatur from '../api/Donatur'

    export default {
        name: 'DetailDonatur',
        created() {
            this.donaturDetail = new Donatur();
            this.donaturDetail.getDetail(this.idDonatur)
                .then(async response => {
                    this.detailDonatur = response.data
                })
                .catch(error => {
                   this.$router.push('/daftardonatur')
                })
            if (this.$store.state.user.role == 'Super Admin') {
                this.isSuperAdmin = true;
            }
        },
        props: {
            idDonatur: String,
        },
        data() {
            return {
                detailDonatur: [],
                donaturEntry: false,
                form: {},
				id: '',
				nama: '',
				email: '',
				tgl_lahir: '',
				jenis_kelamin: '',
				jenis_kelamins: [
                    {label: 'Laki-laki', value: 'L'},
                    {label: 'Perempuan', value: 'P'},
                ],
				telepon: '',
				pekerjaan: '',
				alamat: '',
				submitted: false,
                isSuperAdmin: false,
                loadingRegister: false,
            }
        },
        methods: {
            openDonaturEntry() {
                this.id = this.detailDonatur.id
                this.nama = this.detailDonatur.nama
                this.alamat = this.detailDonatur.alamat
                this.jenis_kelamin = this.detailDonatur.jenis_kelamin
                this.tgl_lahir = this.detailDonatur.tgl_lahir
                this.email = this.detailDonatur.email
                this.telepon = this.detailDonatur.telepon
                this.pekerjaan = this.detailDonatur.pekerjaan
                this.donaturEntry = true
            },
            hideDialog() {
                this.detailDonatur.id = this.id
                this.detailDonatur.nama = this.nama
                this.detailDonatur.alamat = this.alamat
                this.detailDonatur.jenis_kelamin = this.jenis_kelamin
                this.detailDonatur.tgl_lahir = this.tgl_lahir
                this.detailDonatur.email = this.email
                this.detailDonatur.telepon = this.telepon
                this.detailDonatur.pekerjaan = this.pekerjaan
                this.donaturEntry = false
            },
            editDonatur() {
                this.form = {
                    id: this.detailDonatur.id,
                    nama: this.detailDonatur.nama,
                    alamat: this.detailDonatur.alamat,
                    jenis_kelamin: this.detailDonatur.jenis_kelamin,
                    tgl_lahir: this.detailDonatur.tgl_lahir,
                    email: this.detailDonatur.email,
                    telepon: this.detailDonatur.telepon,
                    pekerjaan: this.detailDonatur.pekerjaan,
					id_amil: this.$store.state.user.id,
					nama_amil: this.$store.state.user.nama
                }
                this.donaturDetail.update(this.detailDonatur.id, this.form)
					.then(async response => {
						this.donaturEntry = false
						this.$toast.add({severity:'success', summary: 'Berhasil', detail: 'Berhasil mengedit donatur', life: 3000});
						this.donaturDetail.getDetail(this.idDonatur)
                            .then(async response => {
                                this.detailDonatur = response.data
                            })
						this.loadingRegister = false
						})
					.catch(error => {
						this.$toast.add({severity:'error', summary: 'Gagal', detail: 'Gagal mengedit donatur', life: 3000});
					})
            },
            hapusDonatur() {
                this.$confirm.require({
                    message: 'Data yang telah dihapus tidak dapat dikembalikan.',
                    header: 'Yakin hapus data?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.donaturDetail.delete(this.detailDonatur.id)
                            .then(async response => {
                                this.$toast.add({severity:'success', summary: 'Berhasil', detail:this.detailDonatur.nama+' berhasil dihapus', life: 3000});
                                this.$router.push('/daftardonatur')
                            })
                            .catch(error => {
                                this.$toast.add({severity:'error', summary: 'Gagal', detail: 'Gagal menghapus donatur', life: 3000});
                            })
                    }
                });
            }
        },
        mounted() {
        }
    }
</script>